<template>
    <div>
        <Quote/>
        <GoBack next="Modul qabulini to'xtatish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form class="ABC" @submit.prevent="stopAdmission">
                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Modulni tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="course"
                            required
                        >
                            <option
                                v-for="course of getTakeCourses"
                                :key="course.id"
                                :label="course.name"
                                :value="course.id"
                                :class="course.isActive ? 'd-block' : 'd-none'"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="d-flex justify-content-end">
                        <div class="row col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <button-component>
                                Qabulni yakunlash
                            </button-component>
                        </div>
                    </div>

                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu kursga qabul yakunlandi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal">
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import accessMixin from "@/mixin/accessMixin";
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "CourseStopAdmission",
    mixins: [accessMixin],
    components: {ButtonComponent, GoBack, Quote},
    data() {
        return {
            course: ''
        }
    },
    computed: {
        ...mapGetters(['getTakeCourses', 'getMonths', 'getOneCourse']),
    },
    methods: {
        ...mapActions(['fetchAddCourse', 'fetchTakeCourse', 'fetchMonth', 'searchOneCourse', 'stopCourseAdmission']),
        stopAdmission() {
            this.searchOneCourse(this.course)
                .then(() => {
                    this.stopCourseAdmission({id: this.getOneCourse.id, isActive: false})
                        .then(() => {
                            this.$refs['modal-success'].show()
                            setTimeout(this.hideErrorModal, 3000)
                        })
                })
                .catch(() => {
                    console.log('addCourse da xato')
                })
        },
        getCourse(index) {
            console.log(index)
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/cabinet')
        }

    },
    mounted() {
        this.fetchTakeCourse()
        this.fetchMonth()
    }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}

</style>
