<template>
    <button type="button" id="back" class="btn light ps-sm-0 ps-md-5 ms-0 ms-xl-3" @click="$router.back()">
        <span class="back-icon">&blacktriangleleft; </span>  Ortga
    </button>
</template>

<script>

export default {
    name: "GoBackButton",
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
* {
    transform: scale(1.05);
    transition: all 0.7s;
}

#back{
    text-decoration: none  ;
    color: #FFFFFF ;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    border: 0 solid #FFFFFF;
}

.back-icon:hover {

}
</style>
