<template>
    <button
        class="btn btn-primary form-control text-white"
        :type="type"
        @click="$emit('click')"
    >
        <slot class="text-xs"></slot>
    </button>
</template>

<script>
export default {
    name: "AppButton",
    props: {
        type: {type: String, default: 'submit'},
    }
}
</script>

<style scoped>

</style>