<template>
    <div class="Group">
        <nav class="navbar navbar-expand-lg  navbar-dark">
            <div class="col-4 onlyBigSize">
                <GoBackButton/>
            </div>
            <div class="col-8 text-end">
                <router-link class="addCourse pe-md-5 onlyBigSizeR" to="#"> {{ next }}</router-link>
            </div>
        </nav>
    </div>
</template>

<script>
import GoBackButton from "@/components/html/GoBackButton";

export default {
    name: "GoBack",
    props: {
        next: {
            type: String,
            required: true
        }
    },
    components: {
        GoBackButton
    }

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    transition: all 0.7s;
}

*:hover {
    text-decoration: none;
}

.navbar {
    min-height: 87px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 24px;
    line-height: 27px;
    padding: 30px 15px 30px 15px;
}

.navbar:hover {
    background-color: #990098 !important;
}

.addCourse {
    color: #FFFFFF;
}

@media (min-width: 1450px) {
    .onlyBigSize {
        margin-left: 2%;
    }
}
@media (min-width: 1550px) {
    .onlyBigSize {
        margin-left: 4%;
    }
}
@media (min-width: 1600px) {
    .onlyBigSize {
        margin-left: 7%;
    }
}
@media (min-width: 1700px) {
    .onlyBigSize {
        margin-left: 10%;
    }
}


@media (min-width: 1450px) {
    .onlyBigSizeR {
        margin-right: 8%;
    }
}
@media (min-width: 1550px) {
    .onlyBigSizeR {
        margin-right: 14%;
    }
}
@media (min-width: 1600px) {
    .onlyBigSizeR {
        margin-right: 23%;
    }
}
@media (min-width: 1700px) {
    .onlyBigSizeR {
        margin-right: 30%;
    }
}

</style>
