<template>
    <div class="img">
        <img :src="'/img/'+ image +'.png' " alt="..."/>
    </div>
</template>

<script>
export default {
    name: "ImgTag",
    props: {
        image: {
            type: String,
            required: true
        },
    },
}
</script>

<style scoped>

</style>