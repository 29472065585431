<template>
    <div class="container-fluid py-0 py-sm-5">
        <div class="row justify-content-center">

            <div class="d-block d-sm-none col-10"></div>
            <div class="d-block d-sm-none col-10 text-center">
                <div class="justify-content-center img">
                    <ImgTag class="img" image="logo140"/>
                </div>
            </div>
            <div class="d-block d-sm-none col-10 pt-4 text-center">
                <div class="justify-content-center title">
                    <h4 class="fs-1">{{ title }}</h4>
                </div>
            </div>

            <div class="d-none d-sm-block col-0 col-sm-1 col-md-2 col-xxl-3"></div>
            <div class=" d-none d-sm-block col-7 col-sm-7 col-md-6 col-xxl-5 pt-lg-4">
                <div class="title ps-0 ps-sm-4 ms-2 ms-sm-4">
                    <h4 class="fs-1">{{ title }}</h4>
                </div>
            </div>
            <div class="d-none d-sm-block col-4">
                <div class="img">
                    <ImgTag class="img ps-1 w-50" image="logo140"/>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import ImgTag from "@/components/html/ImgTag";

export default {
    name: "Quote",
    components: {ImgTag},
    props: {
        name: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: "Bu yerda dasturlashni o'rganishingiz mumkin"
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

.title h4 {
    /*text-align: justify;*/
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    Align-content: center;
}

.title {
    animation: lightSpeedInLeft;
    animation-duration: 1s;
}

.img {
    animation: flipInX;
    animation-duration: 1s;
}

#quote-t h4 {
    /* Bu yerda dasturlashni o’rganishingiz mumkin */

    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    Align-content: center;

    color: #000000;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 139px;

}

.row {
    margin-top: 120px;
    margin-bottom: 102px;
}
</style>
